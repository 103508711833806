import { isObject } from 'lodash';
import {
  useMemo,
} from 'react';

import { useSelector } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

const getUnits = (
  metrics = [],
  preferredUnits = {},
) => (schema) => {
  if(!schema || !schema.metricSlug) {
    return [];
  }

  const metric = metrics.find(({ slug }) => slug === schema.metricSlug);

  if(!metric || !metric.units) {
    return [];
  }

  const preferredSet = new Set(
    preferredUnits[schema.metricSlug] || []
  );
  const metricPreferredUnits = metric.units.filter(({ slug }) => preferredSet.has( slug ));
  const metricUnits = metricPreferredUnits.length > 0
    ? metricPreferredUnits
    : metric.units;

  if(!schema.allowedUnitSlugs) {
    return metricUnits;
  }

  const allowed = new Set(schema.allowedUnitSlugs);

  const result = metricUnits
    .filter(({ slug }) => allowed.has( slug ))
    .map(unit => ({...unit, metric_slug: schema.metricSlug}));

  if(result.length === 0) {
    return metricUnits;
  }

  return result;
}

const getTupleAvailableUnits = (schema, metrics, preferredUnits) => {
  const components = (isObject(schema.components) && schema.components) || {};
  return Object.keys(components)
    .filter(key => components[key].type === 'quantitative')
    .map(key => {
      return [key, getUnits(metrics, preferredUnits)(components[key])];
    }).reduce((obj, [key, units]) => {
      obj[key] = units;
      return obj;
    }, {});
}

const useAvailableUnits = (schema) => {
  const taxonomies = useSelector(state => state.taxonomies);

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const preferredUnits = useMemo(() => {
    const config = (suborganization && suborganization.config) ||
      (organization && organization.config) ||
      {}
    return config.preferred_units || {};
  }, [
    organization,
    suborganization,
  ]);

  const metrics = useMemo(() => {
    return (
      taxonomies.metrics && taxonomies.metrics.length > 0
      ? taxonomies.metrics
      : []
    ).map(({ metrics }) => metrics || []).reduce((acc, arr) => acc.concat(arr), []);
  }, [
    taxonomies,
  ]);

  const availableUnits = useMemo(() => {
    switch(schema.type) {
      case 'quantitative':
        return getUnits(metrics, preferredUnits)(schema);
      case 'tuple': {
        return getTupleAvailableUnits(schema, metrics, preferredUnits);
      }
      case 'table': {
        if(schema.innerSchema.type === 'tuple') {
          return getTupleAvailableUnits(schema.innerSchema, metrics, preferredUnits);
        }
        return getUnits(metrics, preferredUnits)(schema.innerSchema);
      }
      default:
        return null;
    }
  }, [
    schema,
    metrics,
    preferredUnits,
  ]);

  return availableUnits;
}

export {
  useAvailableUnits,
  getUnits,
};

