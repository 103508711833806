import React from 'react';
import { isEmpty } from 'lodash';

import { cartesian } from 'utils/cartesian';
import {
  deepGet, deepUpdate,
} from 'utils/functional';
import { convertToUnit } from 'utils/units';

import {
  SINGLE_COLUMN_VALUE,
  SUM_SLUG,
  PRODUCT_SLUG,
  PERCENTAGE_SLUG,
  META_SLUG,
  FULL_VALUE_SLUG,
  translateSlug,
} from 'components/TableAnswer/converters/common';
import {
  dimensionToRows,
  getRowTotal,
} from 'components/TableAnswer/converters/row';
import {
  renderExtensibleColumn,
  renderMetaColumn,
  renderColumn,
  tupleComponentToColumn,
  dimensionToColumns,
  getColumnTotals,
} from 'components/TableAnswer/converters/column';


const idFunc = (a) => a;

export const schemaToColumns = (
  intl,
  schema = {},
  config = {},
  edit = false,   // NOTICE: This is either 'false' or a function
  availableUnits = [],
  schemaLabels = {},
  tableDimensions = {},
  value,
  isTarget = false,
  targetEdit = false, // Are we editing target values?
  onPaste = null,
) => {
  const t = intl.messages;
  const {
    dimensions = [],
    innerSchema = {},
  } = schema;
  const metricSlug = innerSchema.metricSlug;

  const isHeterogeneous = innerSchema.type === 'tuple';
  let rowDimensions, dataColumns;

  if(isHeterogeneous) {
    const tupleComponents = innerSchema.components || [];
    const mapTuple = tupleComponentToColumn(
      innerSchema,
      config,
      edit,
      availableUnits,
      tableDimensions,
      (schemaLabels || {}).innerSchema,
      value,
      isTarget,
      targetEdit,
      onPaste,
    );

    rowDimensions = dimensions;
    dataColumns = tupleComponents.map(mapTuple);

    if(
      ((dimensions.slice(-1)[0] || {})?.calculations || []).includes('rowproduct')
    ) {
      dataColumns = dataColumns.concat({
        title: () => <em>{ t.product_row }</em>,
        dataIndex: PRODUCT_SLUG,
        key: PRODUCT_SLUG,
        render: renderMetaColumn(schema.innerSchema, config, schemaLabels,targetEdit,availableUnits),
        className: 'KpiDetail__answer-table-meta',
      })
    }

  } else {
    rowDimensions = dimensions.filter(({ presentation }) => presentation !== 'column');

    const forcePercentage = rowDimensions.length > 0 &&
      (rowDimensions.slice(-1)[0]?.calculations || []).includes('percentage');

    const mapDimension = dimensionToColumns(
      intl,
      innerSchema,
      config,
      edit,
      availableUnits,
      forcePercentage,
      schemaLabels,
      tableDimensions,
      value,
      metricSlug,
      isTarget,
      targetEdit,
      onPaste,
    );

    dataColumns = dimensions
      .filter(({ presentation }) => presentation === 'column')
      .map(mapDimension)
      .reduce((arr, el) => arr.concat(el), []);

    if(!dataColumns || dataColumns.length === 0) {
      const title = innerSchema.type === 'quantitative' && availableUnits.length > 0 && availableUnits[0].symbol
        ? `(${availableUnits[0].symbol})`
        : '';

      dataColumns = [
        {
          title, // TODO: translate
          dataIndex: SINGLE_COLUMN_VALUE,
          key: 'value',
          render: renderColumn(innerSchema, edit, false, config, schemaLabels, targetEdit, null, null, false, onPaste),
        }
      ];
    }
  }

  const rowNameColumns = rowDimensions.map(({
    by,
    source,
  }) => ({
    title: translateSlug( (schemaLabels || {}).dimensionNames )(by),
    dataIndex: `__row_name__${by}`,
    key: by,
    render: source === 'user' && edit ? renderExtensibleColumn(by, edit, onPaste) : idFunc,
  }));

  return [
    ...rowNameColumns,
    ...dataColumns,
  ];
};

export const getCalculatedComponentUnit = (components, calculatedComponentName, availableUnits) => {
  const componentsWithUnits = components.map(
    (component, idx) => ({
      ...component,
      availableUnits: (components.length > 1 ? availableUnits[idx] : availableUnits) || [], // If there is just one component, this means it's a singleton dimension
    })
  );
  const calculatedComponent = componentsWithUnits.find(component => component.name === calculatedComponentName);

  const calculatedComponentUnit = calculatedComponent.availableUnits.find(
    unit => unit.slug === calculatedComponent.allowedUnitSlugs[0]
  );
  return calculatedComponentUnit?.slug || 'number';

}

export const getComponentValuesInCorrectUnit = (allComponents, component, rowComponentValues, allAvailableUnits) => {

  const idxComponent = allComponents.findIndex(item => item.name === component.name);
  const isRowValue = !isEmpty(rowComponentValues) && typeof rowComponentValues.value !== 'undefined' && rowComponentValues.value !== null;
  let rowUnitSlug = (component.allowedUnitSlugs || [])[0];

  if ('source' in component && component.source === 'calculated'){
    rowUnitSlug = getCalculatedComponentUnit(allComponents, component.name, allAvailableUnits);
  } else if (isRowValue) {
    rowUnitSlug = rowComponentValues.unit || (component.allowedUnitSlugs || [])[0];
  }

  const rowConversionValueToUnit = rowUnitSlug && convertToUnit(rowUnitSlug, allAvailableUnits[idxComponent]);
  const rowFinalValue = isRowValue && rowConversionValueToUnit
                        ? rowConversionValueToUnit(rowComponentValues).value 
                        : null;
  const rowFinalTargetValue = !isEmpty(rowComponentValues) && (rowComponentValues || {}).target_value && rowConversionValueToUnit 
                              ? rowConversionValueToUnit({ unit: rowComponentValues.unit, value: rowComponentValues.target_value }).value 
                              : null;
  return { 
    ...rowComponentValues,
    unit: rowUnitSlug,
    value: rowFinalValue,
    target_value: rowFinalTargetValue 
  };

}

export const valueToDataSource = (
  intl,
  columns = [],
  schema = {},
  value = {},
  previous_value = {},
  availableUnits = [],
  schemaLabels = {},
  target = {},
  tableDimensions = {},
  sourceData = {}
) => {
  const t = intl.messages;
  const {
    dimensions = [],
    innerSchema = {},
  } = schema;

  const isHeterogeneous = innerSchema.type === 'tuple';

  const rowDimensions = isHeterogeneous
    ? dimensions
    : dimensions.filter(({ presentation }) => presentation !== 'column');

  const countBase = rowDimensions.length;

  const hasRowProduct = (rowDimensions.slice(-1)[0]?.calculations || []).includes('rowproduct');

  const hasCrossRowTotal = rowDimensions.length > 0 &&
    (rowDimensions.slice(-1)[0]?.calculations || []).includes('total');

  const hasRowCount = rowDimensions.length > 0 &&
    (rowDimensions[0]?.calculations || []).includes('count');

  const hasCrossRowPercentage = rowDimensions.length > 0 &&
    (rowDimensions.slice(-1)[0]?.calculations || []).includes('percentage');

  // NOTICE: We now use the result of schemaToColumns above instead of this...
  /*
  const columnDimensions = isHeterogeneous
    ? []
    : dimensions.filter(({ presentation }) => presentation === 'column');
  */

  const mapDimension = dimensionToRows(
    innerSchema,
    value,
    schemaLabels,
    tableDimensions,
  );
  const ungroupedRows = rowDimensions
    .map(mapDimension)

  const rows = cartesian(...ungroupedRows);
  const { source, source_params } = sourceData;
  const aggregationUnit = sourceData && source === 'aggregated' && source_params.aggregation && source_params.aggregation_unit 
                              ? source_params.aggregation_unit 
                              : null;
  
  const [ columnTotals, prevValueColumnTotals, TargetValueColumnTotals ] = (
      (!hasCrossRowTotal && !hasCrossRowPercentage) ||
      (isHeterogeneous && !hasRowProduct && !hasCrossRowTotal)  ||
      (innerSchema.type !== 'quantitative' && !isHeterogeneous && !hasRowProduct)
    ) ? [[], [], []]
    : [
      getColumnTotals(value, schema, columns, countBase, availableUnits, aggregationUnit, t),
      getColumnTotals(previous_value, schema, columns, countBase, availableUnits, aggregationUnit, t),
      getColumnTotals(target, schema, columns, countBase, availableUnits,aggregationUnit, t)
    ];

  const dataSource = rows.map((row = []) => {
    const rowAddress = row.map(({ slug }) => slug);
    const rowName = row.map(({ name }) => name);
    const rowTargetAux = deepGet(value, rowAddress);
    const prevValueRowTarget = deepGet(previous_value || {}, rowAddress);
    const targetValueRow = deepGet(target || {}, rowAddress);

    const rowNames = rowDimensions.map(({
      by,
      standardItems = [],
      source,
      ...rest
    }, index) => {
      // By default we use what the row has. Ir will be a name or a slug...
      let rowSlug = rowAddress[index];
      let name = rowName[index];
      if(source && (
        source === 'standard' || source === 'user'
      )) {
        // Check if this is a "custom-standard" and replace name
        const item = (standardItems || []).find(({ slug }) => slug === rowSlug);
        const translate = translateSlug( ((schemaLabels || {}).dimensionValues || {})[by] );

        if(item && item.name) {
          name = translate(item.slug) || item.name;
        }
      }

      return {
        [`__row_name__${by}`]: name,
      }
    }).reduce((acc, obj) => ({ ...acc, ...obj }), {});

    const components = innerSchema.components || [];

    const rowTarget = isHeterogeneous && ['aggregated', 'manual'].includes(source)
      ? components.reduce((obj, component) => {
          const rowComponentValues = rowTargetAux[component.name] || {};
          if (component.type === 'quantitative'){
            obj[component.name] = getComponentValuesInCorrectUnit(components, component, rowComponentValues, availableUnits);
          } else {
            obj[component.name] = { ...rowComponentValues };
          }
          return obj;
        }, {})
      : rowTargetAux;

    const rowProduct = !isHeterogeneous
     ? 0
     : components
       .filter(c => c.type === 'quantitative')
       .reduce((acc, c) => {
         const value = rowTarget[c.name]?.value;
         if(acc === null || typeof value === 'undefined') {
           return null;
         }
         return acc * value;
       }, 1);

    const [ rowTotal, previousValueRowTotal, targetValueRowTotal ] = isHeterogeneous || innerSchema.type !== 'quantitative'
      ? [0, 0, 0]
      : [
        getRowTotal(rowTarget, columns, countBase, availableUnits),
        getRowTotal(prevValueRowTarget, columns, countBase, availableUnits),
        getRowTotal(targetValueRow, columns, countBase, availableUnits)
      ];

      // NOTICE: This means that in some cases we will store target_value in the database
      //         We should ignore `target_value` on aggregation. Ref Airtable#1116
      // We nest target value inside KPI value
      if(Object.entries(targetValueRow).length){
        components.forEach(el => {
          if(rowTarget[el.name]) {
            rowTarget[el.name].target_value = targetValueRow[el.name];
          }
        })
      } else if (Object.entries(rowTarget).length){
        // if we delete all previous target & kpi values are set, we set them to null for refresh
        components.forEach(el=>{
          if(rowTarget[el.name]) {
            rowTarget[el.name].target_value  = null
          }
        });
      }

    // Add previous value to columns rendered for components to show variation
    if (isHeterogeneous) {
      components.map(({name}) => name).forEach(componentSlug => {
        if (rowTarget[componentSlug] && prevValueRowTarget[componentSlug]) {
          rowTarget[componentSlug].previous_value = prevValueRowTarget[componentSlug];
        }
      });
    }

    const rowValues = isHeterogeneous
      ? {
          ...rowTarget,
          [PRODUCT_SLUG]: { value: rowProduct },
        }
      : columns.slice(countBase).map(({
          dataIndex,
          hasPercentageValue,
        }, _index) => {

          const units = availableUnits?.[dataIndex] || (
            Array.isArray(availableUnits)
            ? availableUnits
            : []
          );

          const totalUnit = Object.values(value)?.find(
            _value => _value[dataIndex]?.__total_unit
          )?.[dataIndex].__total_unit
          || units?.find(unit => unit.is_base)?.slug;

          const unit = units?.find(
            unit => unit.slug === totalUnit
          );
          const convertToTargetUnit = unit && convertToUnit(unit.slug, units);

          const baseUnit = availableUnits?.find(unit => unit.is_base);
          const convertToBase = baseUnit && convertToUnit(baseUnit.slug, availableUnits);

          const columnIndex = countBase + _index;
          if(dataIndex === SUM_SLUG) {
            // CASE 0: This is a meta-column, do not set here because we set it later on
            return {};
          }
          if(dataIndex === SINGLE_COLUMN_VALUE) {
            // CASE 1: This is a (legacy) single-column table. We consider cross-row percentage only here
            //         Because percentage for this row does not make sense (it would always be 100%)
            return {
              [dataIndex]: rowTarget && hasCrossRowPercentage && columnTotals[columnIndex]
              ? {
                ...rowTarget,
                [PERCENTAGE_SLUG]: (
                  (convertToTargetUnit && rowTarget.value)
                  ? convertToTargetUnit(rowTarget).value
                  : rowTarget.value || 0
                ) / columnTotals[columnIndex],
              } : rowTarget,
            };
          }
          if(hasCrossRowPercentage) {
            // CASE 2: This is possible a multi-column table and the percentage is summed downwards
            return {
              [dataIndex]: rowTarget[dataIndex] && columnTotals[columnIndex]
              ? {
                ...rowTarget[dataIndex],
                [PERCENTAGE_SLUG]: (
                  (convertToTargetUnit && rowTarget[dataIndex].value)
                  ? convertToTargetUnit(rowTarget[dataIndex]).value
                  : rowTarget[dataIndex].value || 0
                ) / columnTotals[columnIndex],
                previous_value: prevValueRowTarget[dataIndex],
                target_value: targetValueRow[dataIndex]?.value
              }
              : { ...rowTarget[dataIndex], previous_value: prevValueRowTarget[dataIndex], target_value: targetValueRow[dataIndex]},
            };
          }

          // CASE 3: This is possible a multi-column table and the percentage (if any) is summed leftwards
          return {
            [dataIndex]: rowTarget[dataIndex] && hasPercentageValue && rowTotal
            ? {
              ...rowTarget[dataIndex],
              [PERCENTAGE_SLUG]: (
                (convertToBase && rowTarget[dataIndex].value)
                ? convertToBase(rowTarget[dataIndex]).value
                : rowTarget[dataIndex].value || 0
              ) / rowTotal,
              previous_value: prevValueRowTarget[dataIndex],
              target_value: targetValueRow[dataIndex]?.value
            }
            : { ...rowTarget[dataIndex], previous_value: prevValueRowTarget[dataIndex],  target_value: targetValueRow[dataIndex] },
          };
        }).reduce((acc, obj) => ({ ...acc, ...obj }), {
          [SUM_SLUG]: {
            value: rowTotal,
            previous_value: previousValueRowTotal,
            target_value: targetValueRowTotal
          },
        });
    return {
      ...rowNames,
      ...rowValues,
      [FULL_VALUE_SLUG]: value,
    };
  });

  const result = hasCrossRowTotal || hasRowCount
    ? [
      ...dataSource,
      {
        [META_SLUG]: true,
        [rowDimensions[0].by]: hasRowCount ? `${t.count_total} (${dataSource.length})` : t.sum_total,
        ...(
          columns
            .slice(countBase)
            .map((column, index) => {

              const { dataIndex } = column;
              let units, totalUnit;
              

              const metricSlug = isHeterogeneous
                ? innerSchema.components.find(
                    component => component.name === dataIndex
                  )?.metricSlug
                : innerSchema.metricSlug;

              if (metricSlug === 'currency') {

                units = [
                  ...new Set(
                    (value ? Object.values(value) : []).map(
                      _value => _value[dataIndex]?.unit
                    ).filter(unit => !!unit) || []
                  )
                ];

                // If we have multiple currency units, then we don't show
                // currency unit in sum at all, because we don't convert
                // currencies
                totalUnit = units.length === 1 ? units[0] : null;

              } else { 

                units = isHeterogeneous 
                            ? availableUnits?.[index - countBase] 
                            : availableUnits?.[dataIndex] || (
                              Array.isArray(availableUnits)
                              ? availableUnits
                              : []
                            );

                const firstDeclaredUnit = Array.isArray(units) && units.length ? units[0].slug : null;
                totalUnit = (value && Object.values(value)?.find(_value => _value[dataIndex]?.__total_unit)?.[dataIndex].__total_unit)
                                  || aggregationUnit
                                  || firstDeclaredUnit
                                  || units?.find(unit => unit.is_base)?.slug;
              }

              // Exclude totals just for heterogeneous tables and percentages
              // This is importante because percentages are mostly used for in-row calculations
              // and do not represent something you can just add up
              if(isHeterogeneous && metricSlug === 'percentage') {
                return null;
              }

              return [dataIndex, {
                value: columnTotals[countBase + index],
                previous_value: prevValueColumnTotals[countBase + index],
                target_value: TargetValueColumnTotals[countBase + index] ? TargetValueColumnTotals[countBase + index] : null,
                unit: totalUnit,
              }]
            })
            .filter(Boolean)
            .reduce((obj, [key, val]) => { obj[key] = val ; return obj; }, {})
        ),
      }
    ]
    : dataSource;

  return result;
};

export const updateTableValueUnits = ({ 
  schema = {},
  value = {},
  availableUnits = {},
  schemaLabels = {},
  tableDimensions = {},
  sourceData
}) => {

  const {
    dimensions = [],
    innerSchema = {},
  } = schema;

  const { source } = sourceData;
  const isHeterogeneous = innerSchema.type === 'tuple';
  const rowDimensions = isHeterogeneous
    ? dimensions
    : dimensions.filter(({ presentation }) => presentation !== 'column');

  const mapDimension = dimensionToRows(
    innerSchema,
    value,
    schemaLabels,
    tableDimensions,
  );
  const ungroupedRows = rowDimensions.map(mapDimension)
  const rows = cartesian(...ungroupedRows);

  const newValue = rows.reduce((obj, row = []) => {

    const rowAddress = row.map(({ slug }) => slug);
    const rowTargetAux = deepGet(value, rowAddress);

    let components = [];
    const singletonColumnDimension = dimensions.find(
      ({presentation, source}) => presentation === 'column'
        && source === 'singleton'
        && innerSchema.source === 'calculated'
    );
    if (singletonColumnDimension) {
      components = [{
        ...innerSchema,
        name: singletonColumnDimension.by,
      }];
    } else if (innerSchema.type === 'tuple') {
      components = schema.innerSchema.components || [];
    }
  
    const rowTarget = (isHeterogeneous || singletonColumnDimension) && ['aggregated', 'manual'].includes(source)
      ? components.reduce((objRow, component, idx) => {
          const rowComponentValues = rowTargetAux[component.name] || {};
          if (component.type === 'quantitative'){
            objRow[component.name] = getComponentValuesInCorrectUnit(components, component, rowComponentValues, availableUnits);
          } else {
            objRow[component.name] = { ...rowComponentValues };
          }
          return objRow;
        }, {})
      : rowTargetAux;
  
      return deepUpdate(obj, rowAddress, rowTarget);

  }, { ...value });

  return newValue;
  
}
